import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { FilmmakerContentsPage } from './Contents';
import FilmmakerOnBoardPage from './WeatherReport';
import { FilmmakerPortalPage } from './portal';
import FilmmakerUploadPage from './Upload';
import { FilmmakerStatisticsList } from './portal/Statistics';
import PartnersAndAffiliates from './PartnersAndAffiliates';
import { getUserRole } from 'src/stores/user/Selectors';
import { isAdmin, isSubscriber } from 'src/utils/acl';
import PayMe from './PayMe';

const FilmmakerPage = () => {
  let { path } = useRouteMatch();
  const history = useHistory();

  const userRole = useSelector(getUserRole);

  if (!(isAdmin(userRole) || isSubscriber(userRole))) {
    history.push(`${path}/subscription`);
  }

  return (
    <div className="px-6 lg:px-10 xl:px-16 my-6 sm:my-12">
      <Switch>
        <Route exact path={path} render={() => <Redirect to={`${path}/portal`} />}></Route>
        <Route exact path={`${path}/portal`}>
          <FilmmakerPortalPage />
        </Route>
        <Route path={`${path}/contents`}>
          <FilmmakerContentsPage />
        </Route>
        <Route path={`${path}/upload`}>
          <FilmmakerUploadPage />
        </Route>
        <Route exact path={`${path}/statistics`}>
          <FilmmakerStatisticsList />
        </Route>
        <Route exact path={`${path}/news-and-weather`}>
          <FilmmakerOnBoardPage />
        </Route>
        <Route exact path={`${path}/partners-and-affiliates`}>
          <PartnersAndAffiliates />
        </Route>
        <Route exact path={`${path}/pay-me`}>
          <PayMe />
        </Route>
        <Route path="*" render={() => <Redirect to={`${path}/portal`} />}></Route>
      </Switch>
    </div>
  );
};

export default FilmmakerPage;
