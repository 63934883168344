import React, { useRef, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import TyphoonAccordion from '../../components/Accordion';
import { useSelector } from 'react-redux';
import { isAdmin, isSubscriber } from '../../utils/acl';
import { API_ENDPOINT_LAMBDA, AppTitle, s3ResourceUrl } from '../../config';
import { Helmet } from 'react-helmet';
import Axios from 'axios';
import { TyphoonBackdrop } from 'src/components/Backdrop';
import { getAccessToken } from 'src/utils';
import Switch from '@material-ui/core/Switch';
import { getUser } from 'src/stores/user/Selectors';

const includesList = [
  'Trailers.',
  'High-resolution key-art.',
  'Closed caption .srt files. ',
  'Cast and crew.',
  'Short synopsis of your film.',
  'Link your film on social media platforms.',
];

const comingSoonList = [
  'Filmmakers share in ad revenue generated by their film.',
  'View streaming analytics in your personal Filmmaker Portal.',
  'Transfer funds your film has earned directly to your bank or PayPal account.',
  'Additional connected channels and TyphoonOnDemand apps where your film will be available.',
];

const enterpriseList = [
  'Custom pricing for large film libraries.',
  'Support help for onboarding of large libraries.',
  'Unlimited storage space.',
  'Premium add-ons included or discounted.',
  'Premium visibility from customers.',
  'Priority customer service.',
];

const SubscriptionPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector(getUser);

  const subscriptionsRef = useRef<HTMLDivElement>(null);
  const [duration, setDuration] = useState<'monthly' | 'yearly'>('monthly');

  const handleScrollToSubscriptionsOnClick = () => {
    if (subscriptionsRef.current) {
      window.scrollTo({ behavior: 'smooth', top: subscriptionsRef.current.offsetTop - 64 });
    }
  };

  const handleCheckout = async () => {
    setIsLoading(true);

    try {
      const response = await Axios.post(
        `${API_ENDPOINT_LAMBDA}/stripe/subscriptions/checkout?isMonthly=${duration === 'monthly'}`,
        undefined,
        {
          headers: { Authorization: `Bearer ${getAccessToken()}` },
        },
      );
      setIsLoading(false);

      window.location.href = response.data.sessionUrl;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  // const handleTrialCheckout = async () => {
  //   setIsLoading(true);

  //   try {
  //     const response = await Axios.post(`${API_ENDPOINT_LAMBDA}/stripe/subscriptions/trial`, undefined, {
  //       headers: { Authorization: `Bearer ${getAccessToken()}` },
  //     });
  //     setIsLoading(false);
  //     let responseData = response.data;
  //     if (responseData.success) {
  //       TyphoonToaster(responseData.message, 'success');
  //     } else {
  //       TyphoonToaster(responseData.message, 'danger');
  //     }
  //   } catch (error: any) {
  //     console.log(error);
  //     setIsLoading(false);
  //   }
  // };

  return (
    <div className="container mx-auto my-6 space-y-24 sm:my-20">
      <Helmet titleTemplate={`%s | ${AppTitle}`}>
        <title>Subscribe</title>
      </Helmet>
      <TyphoonBackdrop open={isLoading} />


      <div
        className="px-6 py-28 space-y-6 bg-contain bg-typGreen/10 rounded-md sm:px-10"
        style={{
          backgroundImage: `url(/images/swirl.png)`,
          backgroundPosition: 'right',
          backgroundRepeat: 'no-repeat',
          padding: '120px',
        }}
      >
        {user ? (
          isSubscriber(user.role) || isAdmin(user.role) ? (
            <div className="space-y-1">
              <h2 className="text-lg font-semibold">
                <span className="text-paleYellow">Hello,</span> {`${user.given_name} ${user.family_name}`}
              </h2>
              <p className="font-semibold">
                You are <span className="text-lg">{isAdmin(user.role) ? 'an ' : 'a '}</span>
                <span className="text-lg text-paleYellow">{isAdmin(user.role) ? 'Admin' : 'Subscriber'}</span>
              </p>
            </div>
          ) : (
            <div className="space-y-1">
              <h2 className="text-lg font-semibold">
                <span className="text-paleYellow">Hello,</span> {`${user.given_name} ${user.family_name}`}
              </h2>
              <p className="font-semibold">
                You are <span className="text-lg">a</span> <span className="text-lg text-paleYellow">Free User</span>.
              </p>
              <button
                onClick={handleScrollToSubscriptionsOnClick}
                className="inline-block px-5 py-2 text-sm font-semibold text-black rounded-md bg-typGreen"
              >
                Upgrade to Filmmaker Subscription
              </button>
            </div>
          )
        ) : (
          <button
            onClick={handleScrollToSubscriptionsOnClick}
            className="inline-block px-5 py-2 text-med font-semibold text-black rounded-md bg-typGreen"
          >
            Filmmaker Subscription
          </button>
        )}
      </div>

      <div className="text-center space-y-12">
        <h1 className="text-4xl font-semibold text-typGreen">
          TyphoonOnDemand <span className="text-paleYellow">is a self-service Video on Demand platform.</span>
        </h1>
        <div className="space-y-4 text-2xl">
          <p>Upload to TyphoonOnDemand.</p>
          <p>Show your film on Roku, Amazon FireTV and Typhoon online.</p>
          <p className="italic">More apps and channels to come!</p>
        </div>
      </div>

  {/*
      <div className="max-w-3xl mx-auto space-y-20">
        <div className="space-y-6">
          <h1 className="text-center text-2xl text-paleYellow">
            Subscribe to <span className="text-typGreen">Typhoon On Demand</span> for an ad-free experience!
          </h1>
          <div className="text-xl text-center space-y-2">
            <p>
              With a $9.99 monthly or $99.99 yearly subscription you can watch all the content that{' '}
              <span className="text-typGreen">Typhoon</span> has to offer, COMMERCIAL FREE!
            </p>
            <p><br></br>
              PLUS, get full access to live events in the{' '}
              <span className="text-typGreen">Typhoon Virtual Theater</span>.
            </p>
            <p><br></br>
              <span className="text-typGreen">Typhoon</span> is also a self-service VOD platform for filmmakers.
            </p>
            <p>
              Upload and stream your film directly to a growing audience! This includes the ability to upload your film
              and series, including trailers.
            </p>
            <p>
              All film uploads are subject to approval before going live on{' '}
              <span className="text-typGreen">Typhoon</span>.
            </p>
            <div className="italic"><br></br>
              Be part of a growing indie filmmaker community on{' '}
              <span className="text-typGreen">Typhoon On Demand</span>!
            </div>
          </div>
        </div>
         <div className="space-y-6">
          <h2 className="text-3xl font-semibold text-center">Make a Free Account and Upload Today!</h2>
          <video autoPlay={false} muted={false} className="w-full" controls>
            <source src={`${s3ResourceUrl}/videos/welcome_video_v3.mp4`} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
        </div> */}

        <div className="space-y-10">
          <h1 ref={subscriptionsRef} className="pt-4 text-3xl font-semibold text-center text-typGreen">
            Subscription
          </h1>
          <div
            style={{
              backgroundImage: `url(/images/subscription-bg.jpg)`,
            }}
            className="max-w-md mx-auto transition-shadow duration-200 ease-out bg-no-repeat bg-cover rounded-xl hover:shadow-subscription"
          >
            <div>
              <img src="/images/swirl-with-label.png" alt="typhoon_logo_banner" />
              <div className="-mt-5">
                <h2 className="text-xl font-semibold text-center text-typGreen">
                  ${duration === 'monthly' ? 9.99 : 99.99}
                </h2>
                <div className="flex items-center justify-center">
                  <span className={`text-lg font-semibold ${duration === 'monthly' ? 'text-typGreen' : 'text-white'}`}>
                    Monthly
                  </span>
                  <Switch
                    checked={duration === 'yearly'}
                    onChange={(event: any) => setDuration(event.target.checked ? 'yearly' : 'monthly')}
                    color="secondary"
                    name="duration"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                  <span className={`text-lg font-semibold ${duration === 'yearly' ? 'text-typGreen' : 'text-white'}`}>
                    Yearly
                  </span>
                </div>
              </div>
            </div>
            <div className="px-8 pt-3 pb-8">
              <div className="text-sm font-semibold">
                <div className="py-5 space-y-4 border-t border-typGrey10">
                  <div className="flex items-start">
                    <div className="w-2 h-2 mt-2 mr-4 rounded-full bg-typGreen" />
                    <div className="w-11/12">You’re in control.</div>
                  </div>
                  <div className="flex items-start">
                    <div className="w-2 h-2 mt-2 mr-4 rounded-full bg-typGreen" />
                    <div className="w-11/12">Up to 3 movies or 1 series.</div>
                  </div>
                  <div className="flex items-start">
                    <div className="w-2 h-2 mt-2 mr-4 rounded-full bg-typGreen" />
                    <div className="w-11/12">
                      Payout is $0.03 per streaming hour. No convoluted CER (Customer Engagement Ranking).
                    </div>
                  </div>
            
                  <div className="flex items-start">
                    <div className="w-2 h-2 mt-2 mr-4 rounded-full bg-typGreen" />
                    <div className="w-11/12">
                    Top voted key art promoted on social media.
                    </div>
                  </div>


                  <div className="flex items-start">
                    <div className="w-2 h-2 mt-2 mr-4 rounded-full bg-typGreen" />
                    <div className="w-11/12">Stream across multiple platforms: Web, Roku, Amazon Fire TV and more.</div>
                  </div>

                  <div className="flex items-start">
                    <div className="w-2 h-2 mt-2 mr-4 rounded-full bg-typGreen" />
                    <div className="w-11/12">Easily monetize dormant content or promote new films and first looks.</div>
                  </div>
                  <div className="flex items-start">
                    <div className="w-2 h-2 mt-2 mr-4 rounded-full bg-typGreen" />
                    <div className="w-11/12">Full access to live and special events in the Typhoon Theater.</div>
                  </div>
                  <div className="flex items-start">
                    <div className="w-2 h-2 mt-2 mr-4 rounded-full bg-typGreen" />
                    <div className="w-11/12">
                      Join the{' '}
                      <NavLink
                        to={{ pathname: 'https://www.facebook.com' }}
                        target="_blank"
                        rel="noopener"
                        className="inline-block text-typGreen"
                      >
                        Typhoon Inner Circle
                      </NavLink>{' '}
                      on Facebook.
                    </div>
                  </div>
                </div>
                <div className="py-5 space-y-4 border-t border-typGrey10">
                  <h2 className="text-lg">Includes:</h2>
                  {includesList.map((text, i) => (
                    <div key={i} className="flex items-start">
                      <div className="w-2 h-2 mt-2 mr-4 rounded-full bg-typGreen" />
                      <div className="w-11/12">{text}</div>
                    </div>
                  ))}
                  <div className="flex items-start">
                    <div className="w-2 h-2 mr-4 text-typGreen text-2xl">*</div>
                    <div className="w-11/12 italics">
                      All film uploads are subject to approval before going live on Typhoon.
                    </div>
                  </div>
                </div>
                <div className="pt-5 space-y-4 border-t border-typGrey10">
                  <h2 className="text-lg">Under development and coming soon:</h2>
                  {comingSoonList.map((text, i) => (
                    <div key={i} className="flex items-start">
                      <div className="w-2 h-2 mr-4 text-typGreen text-2xl">*</div>
                      <div className="w-11/12">{text}</div>
                    </div>
                  ))}
                </div>
                <div className="text-base text-center pt-4">
                  {user &&
                    (isSubscriber(user.role) ? (
                      <p className="text-lg font-semibold text-center text-paleYellow">
                        Thank you for your subscription!
                      </p>
                    ) : (
                      <button
                        type="button"
                        onClick={() => handleCheckout()}
                        className="px-5 py-2 font-semibold text-black rounded-md bg-typGreen"
                      >
                        Subscribe
                      </button>
                    ))}
                  {!user && (
                    <p className="font-semibold text-center">
                      Please,{' '}
                      <NavLink to="/login" className="underline text-typGreen">
                        login
                      </NavLink>{' '}
                      first!
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <h3 className="text-3xl font-bold tracking-tight text-white">Enterprise</h3>
            <Link
              to="/support"
              className="mt-10 inline-block rounded-md bg-typGreen px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-typGreen/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Contact Us
            </Link>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-typGreen">What’s included</h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul className="mt-8 grid grid-cols-1 gap-4 text-base leading-5 text-white">
              {enterpriseList.map((text) => (
                <li key={text} className="flex gap-x-3">
                  <div className="w-2 h-2 mt-2 mr-4 rounded-full bg-typGreen" />
                  <p>{text}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="max-w-base py-7 mx-auto text-center">
          <img className="inline-block w-auto" src="/images/stripe-logo.png" alt="Stripe Lock Logo" />
          <p className="text-sm">All transactions are managed by STRIPE. We do not collect credit card information.</p>
        </div>

        <div className="flex flex-col items-center justify-center">
          {/* <h3 className="text-xl italic font-semibold">
            14 day free trial. No credit card needed. Upload your films or series. You'll be notified when your free
            trial has expired. If you chose not to subscribe to Typhoon, all uploads will be removed.
          </h3>
          <h3 onClick={handleTrialCheckout} className="text-xl font-semibold underline cursor-pointer text-typGreen">
            Click here for trial subscription
          </h3> */}
        </div>
        <div className="space-y-6">
          <h1 className="text-3xl font-semibold text-center text-typGreen">Frequently Asked Questions</h1>
          <div className="space-y-3">
            <TyphoonAccordion
              title="What is the Typhoon Theater?"
              body="Have a movie premiere or screening streamed live in the Typhoon Theater! Sell tickets to your special event. 
              The Typhoon Theater is full-broadcast HD and looks great! Contact support for more information. Additional cost."
            />
            <TyphoonAccordion
              title="What is the minimum age to use Typhoon?"
              body="Typhoon On Demand has a minimum age requirement of 18 years old. To subscribe as a filmmaker and upload content you must be at least 18 years old."
            />
            <TyphoonAccordion
              title="Can I edit my submission even after it’s been uploaded and made available?"
              body="Yes- from your personal filmmaker portal."
            />
            <TyphoonAccordion
              title="Do I keep 100% of my film rights?"
              body="Yes, you keep 100% of your rights to your content."
            />
            <TyphoonAccordion
              title="Is Typhoon nonexclusive?"
              body="Yes, Typhoon is nonexclusive. You can still work with aggregators, sales agents and distributors."
            />
            <TyphoonAccordion
              title="How do I get paid?"
              body="Payout is $0.03 per streaming hour, per quarter, via PayPal. This means you receive $0.03 for every hour your film is streamed on the Typhoon platform, calculated quarterly, whether it’s in-app, like Roku, or directly from the website. Typhoon payouts will be accessed directly from your Filmmaker Portal."
            />
            <TyphoonAccordion
              title="What formats does Typhoon accept?"
              body="Typhoon is beyond film. You can submit TV, docs, digital first, family… Any
              format, genre or language. With Typhoon you can monetize dormant assets and fill those
              distribution gaps."
            />
            <TyphoonAccordion
              title="What territories or countries will my film be available in?"
              body="We are currently serving the entire US, Canada, EU, UK and Asia Pacific. Typhoon does not stream in Russia."
            />
            <TyphoonAccordion
              title="Typhoon License Agreement"
              body={
                <div>
                  <div>Typhoon filmmaker agreement.</div>
                  <NavLink
                    to={{ pathname: `${s3ResourceUrl}/filmmaker_docs/Typhoon-License-Agreement.pdf` }}
                    target="_blank"
                    rel="noopener"
                    className="text-typGreen text-semibold"
                  >
                    Download
                  </NavLink>
                </div>
              }
            />
          </div>
        </div>
      </div>

  );
};

export default SubscriptionPage;
